import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
};

const Spacer = ({ className }) => (
  <div
    className={classNames('my-2 w-full border-b border-gray-100 border-solid cursor-default', {
      [className]: !!className,
    })}
  />
);

Spacer.propTypes = propTypes;

export { Spacer };
