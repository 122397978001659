import React, { Fragment, useCallback, useContext } from 'react';

import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DropdownContext } from '../DropdownContext';

const propTypes = {
  position: PropTypes.oneOf(['default', 'left', 'right', 'fixed']), // where does dropdown come out from the button?
  alignment: PropTypes.oneOf(['left', 'right']), // When dropdown is in default position, does it align to right or left of button?
  direction: PropTypes.oneOf(['down', 'up']), // Does dropdown drop up or down?
  spacing: PropTypes.string, // Space between dropdown and button
  translateY: PropTypes.number,
  translateX: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const Items = ({
  position: positionOverride,
  alignment: alignmentOverride,
  direction: directionOverride,
  spacing: spacingOverride,
  translateY: translateYOverride,
  translateX: translateXOverride,
  children,
}) => {
  const {
    position: positionDefault,
    alignment: alignmentDefault,
    direction: directionDefault,
    spacing: spacingDefault,
    noPadding,
    translateX: translateXDefault,
    translateY: translateYDefault,
  } = useContext(DropdownContext);

  // Allows us to use the values passed to Dropdown or override them directly (like we do for nested dropdowns)
  const position = positionOverride || positionDefault;
  const alignment = alignmentOverride || alignmentDefault;
  const direction = directionOverride || directionDefault;
  const spacing = spacingOverride || spacingDefault;
  const translateY = translateYOverride || translateYDefault;
  const translateX = translateXOverride || translateXDefault;

  const getMargin = useCallback(() => {
    const getCenterAlignment = () => (alignment === 'left' ? { left: 0 } : { right: 0 });

    const getLeftRightAlignment = () => (direction === 'up' ? { bottom: 0 } : { top: 0 });

    if (position === 'fixed') {
      if (direction === 'up') {
        return {
          position: 'fixed',
          marginBottom: spacing,
          zIndex: 1000,
          transform: `translate(${translateX || 90}%,${translateY || -100}%)`,
        };
      }
      return {
        position: 'fixed',
        marginTop: spacing,
        zIndex: 1000,
        transform: `translate(${translateX || 90}%,${translateY || 100}%)`,
      };
    }

    if (position === 'default') {
      if (direction === 'up') {
        return { bottom: '100%', marginBottom: spacing, ...getCenterAlignment() };
      }
      return { top: '100%', marginTop: spacing, ...getCenterAlignment() };
    }

    if (position === 'left') {
      return { right: '100%', marginRight: spacing, ...getLeftRightAlignment() };
    }
    return { left: '100%', marginLeft: spacing, ...getLeftRightAlignment() };
  }, [position, alignment, direction, spacing, translateX, translateY]);

  const margin = getMargin();

  return (
    <Transition
      as={Fragment}
      className="z-1000"
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        style={margin}
        data-testid="dropdown_item"
        className={classNames(
          'absolute bg-white rounded-md ring-1 ring-black/5 shadow-lg origin-top-right focus:outline-none',
          {
            'py-2': !noPadding,
          },
        )}
      >
        {children}
      </Menu.Items>
    </Transition>
  );
};

Items.propTypes = propTypes;

export { Items };
