import React, { Fragment } from 'react';

import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  Icon: PropTypes.elementType,
  variant: PropTypes.oneOf(['outlined', 'text-light', 'text-dark', 'text-blue']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  hideIcon: PropTypes.bool,
};

const Button = ({ children, onClick, disabled = false, variant = 'outlined', Icon = ChevronDownIcon, hideIcon }) => {
  const defaultClasses =
    'inline-flex justify-center items-center w-full text-base font-medium leading-6 border border-solid focus:outline-none';
  const themeClasses = {
    ['text-light']: 'text-white hover:text-gray-300 bg-transparent border-none shadow-none',
    ['text-dark']: 'text-gray-700  bg-transparent border-none shadow-none',
    ['text-blue']: 'text-light-blue bg-transparent border-none shadow-none',
    outlined: 'text-gray-700 bg-white hover:bg-gray-50 border-gray-700 shadow-sm',
  }[variant];

  const shapeClasses = 'rounded-md';

  const sizeClasses = variant === 'outlined' ? 'px-4 py-2' : 'p-0';

  const buttonClasses = classNames(`${defaultClasses} ${themeClasses} ${shapeClasses} ${sizeClasses}`, {
    'opacity-30 cursor-not-allowed': disabled,
  });

  return (
    <Menu.Button as={Fragment}>
      <button
        data-testid="dropdown_button"
        type="button"
        onClick={onClick}
        disabled={disabled}
        className={buttonClasses}
      >
        <div className="flex items-center justify-center w-full">
          {children}
          {hideIcon ? null : <Icon className="w-5 h-5 ml-auto" />}
        </div>
      </button>
    </Menu.Button>
  );
};

Button.propTypes = propTypes;

export { Button };
