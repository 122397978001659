/* This example requires Tailwind CSS v2.0+ */
import React, { useMemo } from 'react';

import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from './Button';
import { DropdownContext } from './DropdownContext';
import { Item } from './Item';
import { Items } from './Items';
import { Section } from './Section';
import { Spacer } from './Spacer';

const propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf(['default', 'left', 'right', 'fixed']), // where does dropdown come out from the button?
  alignment: PropTypes.oneOf(['left', 'right']), // When dropdown is in default position, does it align to right or left of button?
  direction: PropTypes.oneOf(['down', 'up']), // Does dropdown drop up or down?
  spacing: PropTypes.string, // Space between dropdown and button
  noPadding: PropTypes.bool, // Whether the dropdown should have top and bottom padding
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  translateX: PropTypes.number,
  translateY: PropTypes.number,
};

const Dropdown = ({
  position = 'default', // where does dropdown come out from the button?
  alignment = 'left', // When dropdown is in default position, does it align to right or left of button?
  direction = 'down', // Does dropdown drop up or down?
  spacing = '0.5rem', // Space between dropdown and button
  noPadding = false,
  children,
  className,
  translateX,
  translateY,
}) => {
  const dropdownContextValue = useMemo(
    () => ({ position, alignment, direction, spacing, noPadding, translateX, translateY }),
    [position, alignment, direction, spacing, noPadding, translateX, translateY],
  );

  return (
    <DropdownContext.Provider value={dropdownContextValue}>
      <Menu as="div" className={classNames('inline-block relative text-left', { [className]: !!className })}>
        {children}
      </Menu>
    </DropdownContext.Provider>
  );
};

Dropdown.propTypes = propTypes;

Dropdown.Item = Item;
Dropdown.Items = Items;
Dropdown.Button = Button;
Dropdown.Spacer = Spacer;
Dropdown.Section = Section;

export { Dropdown };
