import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const Section = ({ className, children }) => (
  <div
    className={classNames({
      [className]: !!className,
    })}
  >
    {children}
  </div>
);

Section.propTypes = propTypes;

export { Section };
