import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './LoadingOverlay.module.scss';

const propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  height: PropTypes.oneOf(['small', 'height']),
  className: PropTypes.string,
};

const LoadingOverlay = ({ children, className, loading = true, height }) => {
  const { t } = useTranslation('home');
  return (
    <div className={classnames(className, { [styles.height]: height === 'height', [styles.container]: loading })}>
      {loading && (
        <Spinner
          className={height === 'small' ? styles.small : styles.spinner}
          animation="border"
          variant="primary"
          role="status"
        >
          <span className="sr-only">{`${t('loading')}...`}</span>
        </Spinner>
      )}
      <div className={loading ? styles.content_container : ''}>{children}</div>
    </div>
  );
};

LoadingOverlay.propTypes = propTypes;

export default LoadingOverlay;
