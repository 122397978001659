import React, { useContext, Fragment } from 'react';

import { Menu } from '@headlessui/react';
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Link from 'components/Link';

import { DropdownContext } from '../DropdownContext';
import { Items } from '../Items';

const propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showDisabled: PropTypes.bool,
  centerContent: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

const Item = ({
  content,
  Icon = null,
  selected = false,
  children = null,
  disabled = false,
  showDisabled = false,
  centerContent = false,
  className = '',
  iconClassName = '',
  ...restProps
}) => {
  const { position, alignment } = useContext(DropdownContext);
  const showItem = !disabled || showDisabled;

  const mergedIconClassName = twMerge(
    classNames('mr-3 w-5 h-5 text-gray-400 group-hover:text-gray-500', {
      [iconClassName]: !!iconClassName,
    }),
  );

  const getIcon = () => (
    <div className="flex items-center">
      {Icon && <Icon className={mergedIconClassName} aria-hidden="true" />}
      {content}
    </div>
  );

  const nestedMenuPosition = () => {
    if (position === 'default') {
      // If menu is below or above button, position for nested menus should be opposite the aligment
      if (alignment === 'right') {
        return 'left';
      }
      return 'right';
    }

    // If menu is left or right of button, position for nested menus should be same as position
    return position;
  };

  return showItem ? (
    <div>
      {children ? (
        <Menu as="div" className={classNames('inline-block relative text-left')}>
          <Menu.Button
            disabled={disabled}
            className="inline-flex justify-between items-center py-3 px-4 w-full text-sm hover:text-gray-700 hover:bg-gray-100"
          >
            {getIcon()}

            <ChevronRightIcon className="-mr-1 ml-2 w-5 h-5 text-gray-400" aria-hidden="true" />
          </Menu.Button>

          <Items buttonWidth={60} position={nestedMenuPosition()}>
            {children}
          </Items>
        </Menu>
      ) : (
        <Menu.Item {...restProps} as={restProps?.href ? Link : Fragment}>
          {({ active, close }) => (
            <div
              disabled={disabled}
              type="button"
              className={twMerge(
                classNames(
                  'flex cursor-pointer items-center px-4 py-2 text-sm w-full whitespace-nowrap hover:text-gray-900 hover:bg-gray-100',
                  {
                    'text-gray-400': disabled,
                    'bg-gray-100 text-gray-900': active && !disabled,
                    'text-blue-600': selected,
                    'justify-center': centerContent,
                    'justify-between': !centerContent,
                    [className]: !!className,
                  },
                ),
              )}
            >
              <div className="flex items-center w-full text-left" onClick={close}>
                {Icon && <Icon className={mergedIconClassName} aria-hidden="true" />}
                {content}
              </div>
              {selected && <CheckIcon className="mr-1 w-6 h-6" />}
            </div>
          )}
        </Menu.Item>
      )}
    </div>
  ) : null;
};

Item.propTypes = propTypes;

export { Item };
