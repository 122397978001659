import { createContext } from 'react';

const DropdownContext = createContext({
  position: '',
  alignment: '',
  direction: '',
  spacing: '',
});

export { DropdownContext };
