import React, { useContext } from 'react';

import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Dropdown } from 'components/_common/Dropdown';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { RouterContext } from 'components/_context/RouterContext';
import { languageOptions } from 'utils/localeConstants';

import CookieNames from 'constants/cookieNames';

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'text-light', 'text-dark', 'text-blue']),
  direction: PropTypes.oneOf(['down', 'up']),
};

const ChangeLanguageButton = ({ className, variant, direction }) => {
  const { t } = useTranslation('common');
  const { language, supportedLanguages } = useContext(InternationalizationContext);
  const { router } = useContext(RouterContext);

  const supportedLangOptions = languageOptions.filter((option) => supportedLanguages?.includes(option.value));

  const dropdownOptions = supportedLangOptions.map((option) => {
    const { asPath } = router;
    const locale = 'en' === option.value ? '' : option.value;

    const label = t(option.value.toUpperCase());

    return {
      value: option.value,
      label,
      href: asPath,
      locale,
      // need to be full reload in order for industry slugs to work, because redirection is happening in getServerSideProps
      // if we don't do full reload, getServerSideProps will never we invoked
      fullReload:
        asPath.includes('/companies') || asPath.includes('/browse-industries') || asPath.includes('/browse-locations')
          ? true
          : false,

      onClick: () => {
        Cookies.set(CookieNames.selectedLanguage, option.value);
      },
    };
  });

  if (!dropdownOptions?.length) return null;

  return (
    <Dropdown className={className} direction={direction}>
      <Dropdown.Button variant={variant}>
        <div className={twMerge(classNames('mr-2', className))}>{language?.toUpperCase()}</div>
      </Dropdown.Button>
      <Dropdown.Items>
        {dropdownOptions
          .filter(({ value }) => value !== language)
          .map(({ value, label, ...restProps }) => (
            <Dropdown.Item key={value} content={label.toUpperCase()} {...restProps} />
          ))}
      </Dropdown.Items>
    </Dropdown>
  );
};

ChangeLanguageButton.propTypes = propTypes;

export default ChangeLanguageButton;
